import * as React from "react"
import { Container, Col, Row } from 'react-bootstrap'
import "./annualsummits.scss"
import { useTranslation } from "gatsby-plugin-react-i18next"


const AnnualSummits = ({ className }) => {
    const { t } = useTranslation("about", { keyPrefix: "AnnualSummits" })
    return (
        <section id="annualsummits" className={(className ? className + " " : "") + "annual-summits"}>
            <Container fluid className="lr">
                <Row className="back-annual">
                    <div className="max-container-big">
                        <Col className="annual-head" xs={12} sm={12} md={12} lg={12}>
                            <h4 className="header-square white md-large">{t("Annual Summits")}</h4>
                        </Col>
                        <Col className="annual-text" xs={12} sm={12} md={12} lg={12}>
                            <h1>{t("We achieve success together")}</h1>
                            <p>
                                {t("Once a year, all of us gather and discuss the things we want to see happen in our company. No subject is taboo")}
                                {t("employee benefits, communication, promotions, team bonding, etc. Employees vote on the topics to be discussed and then on the proposals. This is our legislative power.")}
                            </p><br></br>
                        </Col>
                    </div>
                </Row>
            </Container>
            <Container fluid className="hr">
                <Row>
                    <Col lg={6} className="back-annual-high-text">
                        <Row className="back-annual-high-text-container">
                            <Col className="annual-head" xs={12} sm={12} md={12} lg={12}>
                                <h4 className="header-square white md-large">{t("Annual Summits")}</h4>
                            </Col>
                            <Col className="annual-text" xs={12} sm={12} md={12} lg={12}>
                                <h1>{t("We achieve success together")}</h1>
                                <p>
                                    {t("Once a year, all of us gather and discuss the things we want to see happen in our company. No subject is taboo")}
                                    {t("employee benefits, communication, promotions, team bonding, etc. Employees vote on the topics to be discussed and then on the proposals. This is our legislative power.")}
                                </p><br></br>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} className="back-annual-high">
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default AnnualSummits
