import * as React from "react"
//import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"

import "./about.scss"
import "./mainpage.scss"

import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Row } from "react-bootstrap"
import AnnualSummits from "../components/about/annualsummits"
import CompanyLife from "../components/about/companylife"
import Constitution from "../components/about/constitution"
import CouncilTitans from "../components/about/council"
import GivingBack from "../components/about/givingback"
import HeaderAbout from "../components/about/headerabout"
import Leadership from "../components/about/leadership"
import OurCulture from "../components/about/ourculture"
import WeArePeople from "../components/about/wearepeople"
import FindDreamJob from "../components/careers/finddreamjob"
import Ceo from "../components/culture/ceo"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const AboutPage = () => {
    const { t, i18n } = useTranslation()

    return (
        <Layout headerTransparent={true} lang={i18n.resolvedLanguage}>
            <Seo title={t("title", { ns: 'about' })} lang={i18n.resolvedLanguage} description={t("seo.description", { ns: 'common' })} />

            <div className="max-container-pages">
                <Row>
                    <HeaderAbout></HeaderAbout>
                    <OurCulture></OurCulture>
                    <Ceo></Ceo>
                    <Constitution></Constitution>
                    <AnnualSummits></AnnualSummits>
                    <WeArePeople></WeArePeople>

                    <div className="container-background">
                        <CouncilTitans></CouncilTitans>
                        <Leadership></Leadership>
                    </div>
                    <GivingBack />

                    <CompanyLife></CompanyLife>
                    <FindDreamJob></FindDreamJob>
                </Row>
            </div>
        </Layout>
    )
}
export default AboutPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common","about"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
