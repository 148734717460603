import * as React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Carousel from "react-bootstrap/Carousel"
import "./companylife.scss"
import { useTranslation } from "gatsby-plugin-react-i18next"

const CompanyLife = ({ className }) => {
  const { t } = useTranslation("about", { keyPrefix: "CompanyLife" })
  const title = t("Employees Come First")

  const handleKeyDown = (e, carousel) => {
    if (e.key === "ArrowRight") {
      carousel.next()
    } else if (e.key === "ArrowLeft") {
      carousel.prev()
    }
  }

  const carouselRef = React.useRef(null)

  return (
    <section
      id="companylife"
      className={(className ? className + " " : "") + "company-life"}
    >
      <Container fluid>
        <Row>
          <Carousel
            prevLabel="Previous"
            nextLabel="Next"
            interval={null}
            ref={carouselRef}
            onKeyDown={e => handleKeyDown(e, carouselRef.current)}
            tabIndex={0}
          >
            <Carousel.Item className="back-company foto1">
              <div className="max-container-big">
                <Col className="company-head" xs={12} sm={12} md={12} lg={12}>
                  <h4 className="header-square white md-large">
                    {t("Company Life")}
                  </h4>
                </Col>
                <Col className="company-text" xs={12} sm={12} md={12} lg={12}>
                  <h1 className="h1-companylife">{title}</h1>
                  <p>{t("Discovering and Promoting Talent")}</p>
                  <br></br>
                </Col>
              </div>
            </Carousel.Item>
            <Carousel.Item className="back-company foto2">
              <div className="max-container-big">
                <Col className="company-head" xs={12} sm={12} md={12} lg={12}>
                  <h4 className="header-square white md-large">
                    {t("Company Life")}
                  </h4>
                </Col>
                <Col className="company-text" xs={12} sm={12} md={12} lg={12}>
                  <h1 className="h1-companylife">{title}</h1>
                  <p>{t("Creating our Future Together")}</p>
                  <br></br>
                </Col>
              </div>
            </Carousel.Item>

            <Carousel.Item className="back-company foto3">
              <div className="max-container-big">
                <Col className="company-head" xs={12} sm={12} md={12} lg={12}>
                  <h4 className="header-square white md-large">
                    {t("Company Life")}
                  </h4>
                </Col>
                <Col className="company-text" xs={12} sm={12} md={12} lg={12}>
                  <h1 className="h1-companylife">{title}</h1>
                  <p>{t("Recognizing Top-Notch Talents")}</p>
                  <br></br>
                </Col>
              </div>
            </Carousel.Item>

            <Carousel.Item className="back-company foto4">
              <div className="max-container-big">
                <Col className="company-head" xs={12} sm={12} md={12} lg={12}>
                  <h4 className="header-square white md-large">
                    {t("Company Life")}
                  </h4>
                </Col>
                <Col className="company-text" xs={12} sm={12} md={12} lg={12}>
                  <h1 className="h1-companylife">{title}</h1>
                  <p>{t("Recognizing Outstanding People")}</p>
                  <br></br>
                </Col>
              </div>
            </Carousel.Item>
          </Carousel>
        </Row>
      </Container>
    </section>
  )
}

export default CompanyLife
