import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './leadership.scss';

const leadershipData = [
  {
    id: 'rosalba',
    name: 'Rosalba',
    lastName: 'Reynoso',
    title: 'CEO',
    linkedin: 'https://www.linkedin.com/in/rosalba-reynoso-a1969896/',
    description: 'Rosalba has over 20 years of experience in managing companies in the service industry',
    image: 'rosalba',
    team: 'leadership',
  },
  {
    id: 'remi',
    name: 'Remi',
    lastName: 'Vespa',
    title: 'President',
    linkedin: 'https://www.linkedin.com/in/remivespa/',
    description: 'A veteran of the software industry, Remi has gained his experience in the main geographies',
    image: 'remi-lg',
    team: 'leadership',
  },
  {
    id: 'vram',
    name: 'Vram',
    lastName: 'Kouramajian',
    title: 'Chief Mentor',
    // linkedin: "https://www.linkedin.com/in/vramkouramajian/",
    description: 'Vram Kouramajian',
    image: 'vram',
    team: 'leadership',
  },
  {
    id: 'ariel',
    name: 'Ariel',
    lastName: 'Schiera',
    title: 'Technical Director, CTO',
    linkedin: 'https://www.linkedin.com/in/ariel-schiera/',
    description: 'Ariel Schiera',
    image: 'Ariel',
    team: 'management',
  },
  {
    id: 'magdalena',
    name: 'Magdalena',
    lastName: 'Penades',
    title: 'Director of Operations',
    description: 'Magda',
    image: 'magdap',
    team: 'management',
  },
  {
    id: 'graciela',
    name: 'Graciela',
    lastName: 'Peyre',
    title: 'Director of People',
    linkedin: 'https://www.linkedin.com/in/gracielapeyre/',
    description: 'Grace',
    image: 'GracielaPeyre',
    team: 'management',
  },
  {
    id: 'alexis',
    name: 'Alexis',
    lastName: 'Monroy',
    title: 'Head of QA',
    linkedin: 'https://www.linkedin.com/in/alexis-monroy/',
    description: 'Alexis Monroy',
    image: 'AlexisMonroy',
    team: 'management',
    centerHorizontally: true,
  },
  {
    id: 'berenice',
    name: 'Berenice',
    lastName: 'Ramos',
    title: 'Director of Mentoring',
    description: 'Berenice Ramos',
    linkedin: 'https://www.linkedin.com/in/beereniche/',
    image: 'BereniceRamos',
    team: 'management',
    centerHorizontally: true,
  },
  {
    id: 'catherine',
    name: 'Catherine',
    lastName: 'Lewi',
    title: 'Board Member',
    linkedin: 'https://www.linkedin.com/in/catherinelewi/',
    description: 'Ms Lewi is a former executive in charge of global marketing',
    team: 'board',
    image: 'CatherineLewi',
  },
  {
    id: 'david',
    name: 'David',
    lastName: 'Ziegler',
    title: 'Board Member',
    linkedin: 'http://linkedin.com/in/david-s-ziegler-bb255684',
    description: 'A former President and CEO at ACE Hardware',
    image: 'David_Ziegler',
    team: 'board',
  },
  {
    id: 'luc',
    name: 'Dr. Luc Julia',
    lastName: '',
    title: 'Board Member',
    linkedin: 'http://linkedin.com/in/lucjulia',
    description: 'Dr. Luc Julia, Chief Scientific Officer for Renault',
    image: 'julia',
    team: 'board',
  },
  {
    id: 'rosalba-board',
    name: 'Rosalba',
    lastName: 'Reynoso',
    title: 'CEO',
    linkedin: 'https://www.linkedin.com/in/rosalba-reynoso-a1969896/',
    description: 'Rosalba has over 20 years of experience in managing companies in the service industry',
    image: 'Rosalba_Reynoso',
    team: 'board',
    centerHorizontally: true,
  },
  {
    id: 'remi-board',
    name: 'Remi',
    lastName: 'Vespa',
    title: 'President',
    linkedin: 'https://www.linkedin.com/in/remivespa/',
    description: 'A veteran of the software industry, Remi has gained his experience in the main geographies',
    image: 'remi',
    team: 'board',
    centerHorizontally: true,
  },
];
const Leadership = ({ className }) => {
  const { t } = useTranslation('about', { keyPrefix: 'Leadership' });
  const [expandedLeaders, setExpandedLeaders] = React.useState({});
  const [activeTeam, setActiveTeam] = React.useState('leadership');

  const toggleLeaderExpansion = id => {
    setExpandedLeaders(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const renderLeader = leader => {
    const colSize = leader.centerHorizontally ? 6 : 4;
    const xlSize = leader.team === 'leadership' ? 4 : colSize;
    const lgSize = leader.team === 'leadership' ? 6 : colSize;
    return (
      <Col key={leader.id} className='' xl={xlSize} lg={lgSize} md={6} sm={6} xs={6}>
        <div className='max-container'>
          <Col className={`img-leadership ${leader.image}`} xl={12} lg={12} md={12} sm={12} xs={12}></Col>
          <Col className='text-leader-container' xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className='leader-wrapper'>
              <div className='name-wrapper'>
                <div className='name-leader'>{`${leader.name}`}</div>{' '}
                <div className='lastName-leader'>{`${leader.lastName}`}</div>
              </div>
              <div className={'title-leader'}>{t(leader.title)}</div>
              {leader.linkedin && (
                <a className='leader-linkedin' href={leader.linkedin} target='_blank' rel='noreferrer'>
                  <span aria-label='linkedin'></span>
                </a>
              )}
            </div>
            <div className={expandedLeaders[leader.id] ? 'text-leader-full' : 'text-leader'}>
              {t(leader.description)}
            </div>
            <span
              onClick={() => toggleLeaderExpansion(leader.id)}
              onKeyDown={e => {
                // Trigger the toggle function when Enter or Space is pressed
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleLeaderExpansion(leader.id);
                }
              }}
              tabIndex={0}
              role='button'
              aria-label={`${t('Read')} ${expandedLeaders[leader.id] ? t('less') : t('more')} about the leader`}
              className='readmore-leader'
            >
              {t('Read')} {expandedLeaders[leader.id] ? t('less') : t('more')}
            </span>
          </Col>
        </div>
      </Col>
    );
  };

  const renderTeamTab = (teamName, index) => (
    <Col
      key={index}
      className={`${teamName.toLowerCase()} bottom-line ${activeTeam === teamName ? 'teams_active' : ''}`}
      xl={4}
      lg={4}
      md={6}
      sm={6}
      xs={12}
    >
      <div className='max-container-teams'>
        <span
          onClick={() => setActiveTeam(teamName)}
          onKeyDown={e => {
            // Trigger the click function when Enter or Space is pressed
            if (e.key === 'Enter' || e.key === ' ') {
              setActiveTeam(teamName);
            }
          }}
          tabIndex={0} // Makes the span focusable
          role='button' // Indicates it's an interactive element
          aria-label={`Select ${t(teamName)} team`}
          className='team-name-container'
        >
          {t(teamName)}
        </span>
      </div>
    </Col>
  );

  return (
    <section
      id='leadership'
      style={{ paddingTop: '80px', marginTop: '-80px' }}
      className={`${className || ''} leadership-container`}
    >
      <Container fluid>
        <div className='max-container-big'>
          <h6 className='header-square blue md-large lg-large'>{t('leadershipTitle')}</h6>
          <Row>
            <Row className='teams'>
              {['leadership', 'board', 'management'].map((team, index) => renderTeamTab(team, index))}
            </Row>
            <Row className={`team_${activeTeam} display_teams`}>
              {leadershipData.filter(leader => leader.team === activeTeam).map(renderLeader)}
            </Row>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Leadership;
