import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import './wearepeople.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const WeArePeople = ({ className }) => {
  const { t } = useTranslation('about', { keyPrefix: 'WeArePeople' });

  return (
    <section id='wethepeople' className={(className ? className + ' ' : '') + 'wearepeople'}>
      <Container fluid>
        <div className='max-container-big'>
          <Row>
            <h6 className='header-square blue md-large lg-large'>{t('We, the People')}</h6>
          </Row>
          <Row className='over'>
            <Col className='col-left' xl={'auto'} lg={'auto'} md={6} sm={6} xs={6}>
              <div className='photo photo-bg1'></div>
              <p>
                {t('Summit')}
                <br></br>
                <span>Bolivia, 2021</span>
              </p>

              <div className='photo photo-bg2'></div>
              <p>
                {t('Summit')}
                <br></br>
                <span>{t('Spain')}, 2021</span>
              </p>
            </Col>

            <Col className='col-middle' xl={'auto'} lg={'auto'} md={6} sm={6} xs={6}>
              <div className='photo photo-bg3'></div>
              <p>
                {t('Casual dinner')}
                <br></br>
                <span>Uruguay, 2021</span>
              </p>

              <div className='photo photo-bg4'></div>
              <p>
                {t('Summit')}
                <br></br>
                <span>Argentina, 2021</span>
              </p>
            </Col>

            <Col className='col-right' xl={'auto'} lg={'auto'} md={12} sm={12} xs={12}>
              <div className='photo photo-bg6'></div>
              <p className='md-full'>
                {t('Summit')} MX<br></br>
                <span>Mexico, 2021</span>
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default WeArePeople;
