import * as React from "react"
import { Container, Col, Row } from 'react-bootstrap'
import "./ourculture.scss"
import { useTranslation } from "gatsby-plugin-react-i18next"


const OurCulture = ({ className }) => {
    const { t } = useTranslation("about", { keyPrefix: "OurCulture" })
    return (
        <section id="ourculture" className={(className ? className + " " : "") + "our-culture"}>
            <Container fluid>
                <Row>
                    <Col className="" xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="bulletContainer">
                            <h6 className="mobile-culture header-square green md-large lg-large">{t("Our Culture")}</h6>
                            <h6 className="desktop-culture header-square green md-large lg-large">{t("Our Culture")}</h6>
                            {t("Our core objective is not to become the largest company possible, but")}<br />
                            {t("rather an agile and resilient organization centered on the empowerment of")}<br />
                            {t("its stakeholders (employees, clients, suppliers, local environment, etc.).")}<br></br><br></br>

                            <h4 className="title-list">{t("Our business model is predicated")} <br /> {t("on the following beliefs")}</h4><br></br>

                            <h4 className="bullet-culture black-two"><i className="bi bi-dash-lg dash"></i> {t("We believe in our people")}</h4>
                            <h4 className="bullet-culture black-two margin-1"><i className="bi bi-dash-lg dash"></i> {t("We believe in integrity")}</h4>
                            <h4 className="bullet-culture black-two margin-2"><i className="bi bi-dash-lg dash"></i> {t("We believe in patience")}</h4>
                            <h4 className="bullet-culture black-two margin-3"><i className="bi bi-dash-lg dash"></i> {t("We believe in diversity")}</h4>
                            <h4 className="bullet-culture black-two margin-4"><i className="bi bi-dash-lg dash"></i> {t("We believe in empowering people")}</h4>
                            <h4 className="bullet-culture black-two margin-5"><i className="bi bi-dash-lg dash"></i> {t("We believe in creativity and execution, not in competition.")}</h4>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OurCulture
